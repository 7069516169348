<template>
	<div>
        <Dialog style="background: #727376;" :header="$t('Póliza')" v-model:visible="displayview" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true">
            <br>
            <!-- INCENDIO -->
            <div class="confirmation-content" v-if="police.tipo==1">
                <div class="p-field">
                    <h4>{{$t('Información Básica')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-3">
                            {{$t('Fecha')}}: <strong>{{convfech(police.fecha)}}</strong>			
                        </div>
                        <div class="p-col-3">
                            {{$t('Número ID')}}: <strong>{{police.code}}</strong>			
                        </div>
                        <div class="p-col-3" v-if="police.tipocliente!=2">
                            {{$t('Sexo')}}: 
                            <strong v-if="i18n.locale() == 'es'"> {{police.sexnombre}}</strong> 			
                            <strong v-if="i18n.locale() == 'en'"> {{police.sexname}}</strong> 			
                        </div>
                        <div class="p-col-6">
                            {{$t('Nombre Completo')}}: <strong>{{police.nombre}}</strong>			
                        </div>
                        <div class="p-col-6">
                            {{$t('Correo')}}: <strong>{{police.pcorreo}}</strong>			
                        </div>
                        <div class="p-col-4" v-if="police.tipocliente==1">
                            {{$t('Fecha de nacimiento')}}: <strong>{{convfech(police.fechanac)}}</strong>			
                        </div>
                        <div class="p-col-2" v-if="police.tipocliente==1">
                            {{$t('Edad')}}: <strong>{{police.edad}}</strong>			
                        </div>
                        <div class="p-col-6" v-if="i18n.locale() == 'es'">
                            {{$t('Nacionalidad')}}: <strong>{{police.nacionalidad}}</strong> 			
                        </div>
                        <div class="p-col-6" v-else>
                            {{$t('Nacionalidad')}}: <strong>{{police.nationality}}</strong> 			
                        </div>
                        <div class="p-col-4">
                            {{$t('Localidad')}}: <strong>{{police.localidadn}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Teléfono móvil')}}: <strong>{{police.celular}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Teléfono privado')}}: <strong>{{police.tlflocal}}</strong>			
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Documentos Personales')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-4">
                            {{$t('Número ID')}}:v<strong>{{police.code}}</strong>
                        </div>
                        <div class="p-col-4">
                            <img style="width: 100px;" :src="url.upload+police.documento" @click="VerIMG(url.upload+police.documento)"/>
                            <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
                                <img style="width: 350px;" :src="url.upload+police.documento"/>
                            </OverlayPanel>
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Datos de la Propiedad')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-12">
                            {{$t('Dirección')}}: <strong>{{police.direccion}}</strong>
                        </div>
                        <div class="p-col-12">
                            {{$t('Comentario')}}: <strong>{{police.comentario}}</strong>
                        </div>
                        <div class="p-col-6" v-if="police.tipocliente==1">
                            {{$t('Evaluación de la propiedad (mínimo 6 meses)')}}: <strong>{{police.comentario}}</strong>
                        </div>
                        <div class="p-col-6" v-if="police.tipocliente==2">
                            {{$t('Estados financieros - Últimos tres años')}}: <strong>{{police.comentario}}</strong>
                        </div>
                        <div class="p-col-6">
                            <img style="width: 50px;" src="images/pdf.jpg" @click="VerPDF(police.pdf_eval)"/>
                        </div>
                        <div class="p-col-6" v-if="police.tipocliente==1">
                            {{$t('Titulo de propiedad')}}: <strong>{{police.comentario}}</strong>
                        </div>
                        <div class="p-col-6" v-if="police.tipocliente==2">
                            {{$t('Informe Cope')}}: <strong>{{police.comentario}}</strong>
                        </div>
                        <div class="p-col-6">
                            <img style="width: 50px;" src="images/pdf.jpg" @click="VerPDF(police.pdf_tit)"/>
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Cobertura')}}</h4>
                    <DataTable :value="police.descrip1" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                            :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                        <Column :header="$t('Descripción')">
                            <template #body="slotProps">
                                <div v-if="i18n.locale() == 'es'">{{slotProps.data.nombre}}</div>
                                <div v-if="i18n.locale() == 'en'">{{slotProps.data.name}}</div>
                            </template>
                        </Column>
                        <Column :header="$t('Valor')">
                            <template #body="slotProps">
                                {{slotProps.data.valor}}
                            </template>
                        </Column>
                        <Column :header="$t('Rate')+' %'">
                            <template #body="slotProps">
                                {{slotProps.data.evaluo}}
                            </template>
                        </Column>
                    </DataTable>
                </div>			
                <div class="p-field"  v-if="police.evaluacion.tipopoliza==5">
                    <h4>{{$t('Inventario')}}</h4>
                    <DataTable :value="police.inventario" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                            :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                        <Column :header="$t('Descripción')">
                            <template #body="slotProps">
                                {{slotProps.data.opcion}}
                            </template>
                        </Column>
                        <Column :header="$t('Valor')">
                            <template #body="slotProps">
                                {{slotProps.data.valor}}
                            </template>
                        </Column>
                        <Column :header="$t('Rate')+' %'">
                            <template #body="slotProps">
                                {{slotProps.data.evaluo}}
                            </template>
                        </Column>
                    </DataTable>
                </div>			
                <div class="p-field" v-if="police.tipocliente==2">
                    <h4>{{$t('Continuidad de Negocio')}}</h4>
                    <DataTable :value="police.descrip2" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                            :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                        <Column :header="$t('Descripción')">
                            <template #body="slotProps">
                                <div v-if="i18n.locale() == 'es'">{{slotProps.data.nombre}}</div>
                                <div v-if="i18n.locale() == 'en'">{{slotProps.data.name}}</div>
                            </template>
                        </Column>
                        <Column :header="$t('Valor')">
                            <template #body="slotProps">
                                {{slotProps.data.valor}}
                            </template>
                        </Column>
                        <Column :header="$t('Índice')">
                            <template #body="slotProps">
                                {{slotProps.data.evaluo}}
                            </template>
                        </Column>
                    </DataTable>
                </div>			
                <div class="p-field">
                    <h4>{{$t('Evaluación')}}</h4>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-6">
                            {{$t('Tipo de Póliza')}}: 
                            <strong v-if="i18n.locale() == 'es'">{{police.evaluacion.tipopoliza_nombre}}</strong>
                            <strong v-if="i18n.locale() == 'en'">{{police.evaluacion.tipopoliza_name}}</strong>
                        </div>
                        <div class="p-col-6">
                            {{$t('Duración')}}: 
                            <strong v-if="i18n.locale() == 'es'">{{police.duracion_nombre}}</strong>
                            <strong v-if="i18n.locale() == 'en'">{{police.duracion_name}}</strong>
                        </div>
                        <br><br>	
                        <div class="p-col-12">
                            {{$t('Observación')}}: <strong>{{police.evaluacion.comentario}}</strong>
                        </div>
                        <br><br>
                        <div class="p-col-6">
                            {{$t('Pagos')}}: <strong>{{police.cuota_valor}}</strong>
                        </div>	
                        <div class="p-col-6">
                            {{$t('Monto')}}: <strong>{{moneda(police.evaluacion.montofinal)}}</strong>
                        </div>	
                    </div>                   
                </div>
            </div>
            <!-- VEHICULO -->
            <div class="confirmation-content" v-if="police.tipo==2">
                <div class="p-field">
                    <h4>{{$t('Información Básica')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-3">
                            {{$t('Fecha')}}: <strong>{{convfech(police.fecha)}}</strong>			
                        </div>
                        <div class="p-col-3">
                            {{$t('Número ID')}}: <strong>{{police.code}}</strong>			
                        </div>
                        <div class="p-col-3" v-if="police.tipocliente!=2">
                            {{$t('Sexo')}}: 
                            <strong v-if="i18n.locale() == 'es'"> {{police.sexnombre}}</strong> 			
                            <strong v-if="i18n.locale() == 'en'"> {{police.sexname}}</strong> 			
                        </div>
                        <div class="p-col-6">
                            {{$t('Nombre Completo')}}: <strong>{{police.nombre}}</strong>			
                        </div>
                        <div class="p-col-6">
                            {{$t('Correo')}}: <strong>{{police.correo}}</strong>			
                        </div>
                        <div class="p-col-4" v-if="police.tipocliente==1">
                            {{$t('Fecha de nacimiento')}}: <strong>{{police.fechanac}}</strong>			
                        </div>
                        <div class="p-col-2" v-if="police.tipocliente!=2">
                            {{$t('Edad')}}: <strong>{{police.edad}}</strong>			
                        </div>
                        <div class="p-col-6" v-if="i18n.locale() == 'es'">
                            {{$t('Nacionalidad')}}: <strong>{{police.nacionalidad}}</strong> 			
                        </div>
                        <div class="p-col-6" v-else>
                            {{$t('Nacionalidad')}}: <strong>{{police.nationality}}</strong> 			
                        </div>
                        <div class="p-col-4">
                            {{$t('Localidad')}}: <strong>{{police.localidadn}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Teléfono móvil')}}: <strong>{{police.celular}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Teléfono privado')}}: <strong>{{police.tlflocal}}</strong>			
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Información del Vehículo')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-12" v-if="i18n.locale() == 'es'">
                            {{$t('Tipo de Vehículo')}}: <strong>{{police.tipovehnombre}}</strong> 			
                        </div>
                        <div class="p-col-12" v-else>
                            {{$t('Tipo de Vehículo')}}: <strong>{{police.tipovehname}}</strong> 			
                        </div>
                        <div class="p-col-4">
                            {{$t('Marca')}}: <strong>{{police.marca}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Módelo')}}: <strong>{{police.modelo}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Año de construcción')}}: <strong>{{police.veh_ano}}</strong>
                        </div>
                        <div class="p-col-4">
                            {{$t('Año de Vehículo')}}: <strong>{{police.veh_compra}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Automático')}} <i class="pi pi-check" v-if="police.veh_automatico"></i> <i class="pi pi-times" v-else></i>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Número de chasis')}}: <strong>{{police.veh_chasis}}</strong>
                        </div>
                        <div class="p-col-4">
                            {{$t('Nº placa')}}: <strong>{{police.veh_placa}}</strong>
                        </div>
                        <div class="p-col-4">
                            {{$t('Nº asientos')}}: <strong>{{police.veh_asientos}}</strong>
                        </div>
                        <div class="p-col-4">
                            {{$t('Color')}}: <strong :style="'background: #'+police.veh_color+';color:#'+police.veh_color+';'">{{police.veh_color}}</strong>
                        </div>
                        <div class="p-col-4">
                            {{$t('Posición del Volante')}}: <strong>{{police.veh_volante}}</strong>
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>Keuringskaart</h4>
                    <div class="p-grid">
                        <div class="p-col-4">
                            {{$t('Registro')}}:<strong>{{police.cardreg}}</strong>
                        </div>
                        <div class="p-col-4">
                            {{$t('Fecha de emisión')}}:<strong>{{police.cardemi}}</strong>
                        </div>
                        <div class="p-col-4">
                            <img style="width: 100px;" :src="url.upload+police.veh_adverso" @click="VerIMG(url.upload+police.veh_adverso)" />
                            <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
                                <img style="width: 350px;" :src="url.upload+police.veh_adverso"/>
                            </OverlayPanel>
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Información de la Licencia')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-12">
                            <DataTable :value="police.licencias" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                                    :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                                <Column :header="$t('Imagen')">
                                    <template #body="slotProps">
                                        <img style="width: 50px;" :src="url.upload+slotProps.data.adverso" @click="VerIMG(url.upload+slotProps.data.adverso)"/>
                                    </template>
                                </Column>
                                <Column :header="$t('Licencia')">
                                    <template #body="slotProps">
                                        {{slotProps.data.categoria}}
                                    </template>
                                </Column>
                                <Column :header="$t('Número ID')">
                                    <template #body="slotProps">
                                        {{slotProps.data.code}}
                                        <Avatar icon="bi bi-asterisk" class="p-mr-2" style="background-color:blue; color: #ffffff" v-if="slotProps.data.habitual"/>
                                    </template>
                                </Column>
                                <Column :header="$t('Nombre Completo')">
                                    <template #body="slotProps">
                                        {{slotProps.data.nombre}}
                                    </template>
                                </Column>
                                <Column :header="$t('Fecha de emisión')">
                                    <template #body="slotProps">
                                        {{convfech(slotProps.data.emision)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Fecha de caducidad')">
                                    <template #body="slotProps">
                                        {{convfech(slotProps.data.caducidad)}}
                                    </template>
                                </Column>
                                <Column :header="$t('País de emisión')">
                                    <template #body="slotProps" v-if="i18n.locale() == 'es'">
                                        {{slotProps.data.pais_nombre}}
                                    </template>
                                    <template #body="slotProps" v-else>
                                        {{slotProps.data.pais_name}}
                                    </template>
                                </Column>
                            </DataTable>
                            <OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
                                <img style="width: 350px;" :src="imagen"/>
                            </OverlayPanel>
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Información Adicional')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-12">
                            {{$t('¿Tiene alguna discapacidad / enfermedad física o de otro tipo?')}}: <strong v-if="police.preguntas.pregunta1">Yes</strong><strong v-else>NO</strong>
                        </div>
                        <div class="p-col-12">
                            {{$t('¿Has sido condenada penalmente?')}}: <strong v-if="police.preguntas.pregunta2">Yes</strong><strong v-else>NO</strong>
                        </div>
                        <div class="p-col-12">
                            {{$t('¿Alguna vez le han negado el seguro?')}}: <strong v-if="police.preguntas.pregunta3">Yes</strong><strong v-else>NO</strong>
                        </div>
                        <div class="p-col-12">
                            {{$t('¿Existen circunstancias circundantes que sean importantes para aceptar este seguro?')}}: <strong v-if="police.preguntas.pregunta4">Yes</strong><strong v-else>NO</strong>
                        </div>
                        <div class="p-col-12">
                            {{$t('¿O se imponen condiciones agravadas?')}}: <strong v-if="police.preguntas.pregunta5">Yes</strong><strong v-else>NO</strong>
                        </div>
                        <div class="p-col-12">
                            {{$t('¿Descuento por no siniestralidad %?')}}: <strong v-if="police.preguntas.noclain">Yes</strong><strong v-else>NO</strong>
                        </div>
                        <div class="p-col-4" v-if="police.preguntas.noclain">
                            <img style="width: 100px;" :src="url.upload+police.preguntas.document" @click="VerIMG(url.upload+police.preguntas.document)" />
                            <OverlayPanel ref="op2" appendTo="body" :showCloseIcon="true">
                                <img style="width: 350px;" :src="url.upload+police.preguntas.document"/>
                            </OverlayPanel>
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Evaluación')}}</h4>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-6">
                            {{$t('Tipo de Póliza')}}: 
                            <strong v-if="i18n.locale() == 'es'">{{police.evaluacion.tipopoliza_nombre}}</strong>
                            <strong v-if="i18n.locale() == 'en'">{{police.evaluacion.tipopoliza_name}}</strong>
                        </div>
                        <div class="p-col-6">
                            {{$t('Duración')}}: 
                            <strong v-if="i18n.locale() == 'es'">{{police.duracion_nombre}}</strong>
                            <strong v-if="i18n.locale() == 'en'">{{police.duracion_name}}</strong>
                        </div>
                        <br><br>	
                        <div class="p-col-12">
                            {{$t('Observación')}}: <strong>{{police.evaluacion.comentario}}</strong>
                        </div>
                        <br><br>
                        <div class="p-col-6">
                            {{$t('Pagos')}}: <strong>{{police.cuota_valor}}</strong>
                        </div>	
                        <div class="p-col-6">
                            {{$t('Monto')}}: <strong>{{moneda(police.evaluacion.montofinal)}}</strong>
                        </div>	
                    </div>                   
                </div>
            </div>
            <!-- SALUD -->
            <div class="confirmation-content" v-if="police.tipo==3">              
                <div class="p-field">
                    <h4>{{$t('Información Básica')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-3">
                            {{$t('Fecha')}}: <strong>{{convfech(police.fecha)}}</strong>			
                        </div>
                        <div class="p-col-3">
                            {{$t('Número ID')}}: <strong>{{police.code}}</strong>			
                        </div>
                        <div class="p-col-3" v-if="police.tipocliente!=2">
                            {{$t('Sexo')}}: 
                            <strong v-if="i18n.locale() == 'es'"> {{police.sexnombre}}</strong> 			
                            <strong v-if="i18n.locale() == 'en'"> {{police.sexname}}</strong> 			
                        </div>
                        <div class="p-col-6">
                            {{$t('Nombre Completo')}}: <strong>{{police.nombre}}</strong>			
                        </div>
                        <div class="p-col-6">
                            {{$t('Correo')}}: <strong>{{police.correo}}</strong>			
                        </div>
                        <div class="p-col-4" v-if="police.tipocliente!=2">
                            {{$t('Fecha de nacimiento')}}: <strong>{{police.fechanac}}</strong>			
                        </div>
                        <div class="p-col-2" v-if="police.tipocliente!=2">
                            {{$t('Edad')}}: <strong>{{police.edad}}</strong>			
                        </div>
                        <div class="p-col-6" >
                            {{$t('Nacionalidad')}}: 
                            <strong v-if="i18n.locale() == 'es'">{{police.nacionalidad}}</strong>
                            <strong v-if="i18n.locale() == 'en'">{{police.nationality}}</strong> 			
                        </div>
                        <div class="p-col-4">
                            {{$t('Localidad')}}: <strong>{{police.localidadn}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Teléfono móvil')}}: <strong>{{police.celular}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Teléfono privado')}}: <strong>{{police.tlflocal}}</strong>			
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Documentos Personales')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-4">
                            {{$t('Número ID')}}: <strong>{{police.doc_num}}</strong>
                        </div>
                        <div class="p-col-4">
                            <img style="width: 100px;" :src="url.upload+police.doc_img" @click="VerIMG(url.upload+police.doc_img)" />
                            <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
                                <img style="width: 350px;" :src="url.upload+police.doc_img"/>
                            </OverlayPanel>
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Prueba de Risedencia')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-4">
                            {{$t('Tipo')}}: <strong>{{police.resid_tipo}}</strong>
                        </div>
                        <div class="p-col-4">
                            <img style="width: 100px;" :src="url.upload+police.resid_img"  @click="VerIMG(url.upload+police.resid_img)" />
                            <OverlayPanel ref="op2" appendTo="body" :showCloseIcon="true">
                                <img style="width: 350px;" :src="url.upload+police.resid_img"/>
                            </OverlayPanel>
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Evaluación')}}</h4>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-6">
                            {{$t('Tipo de Póliza')}}: 
                            <strong v-if="i18n.locale() == 'es'">{{police.evaluacion.tipopoliza_nombre}}</strong>
                            <strong v-if="i18n.locale() == 'en'">{{police.evaluacion.tipopoliza_name}}</strong>
                        </div>
                        <div class="p-col-6">
                            {{$t('Duración')}}: 
                            <strong v-if="i18n.locale() == 'es'">{{police.duracion_nombre}}</strong>
                            <strong v-if="i18n.locale() == 'en'">{{police.duracion_name}}</strong>
                        </div>
                        <br><br>	
                        <div class="p-col-12">
                            {{$t('Observación')}}: <strong>{{police.evaluacion.comentario}}</strong>
                        </div>
                        <br><br>
                        <div class="p-col-6">
                            {{$t('Pagos')}}: <strong>{{police.cuota_valor}}</strong>
                        </div>	
                        <div class="p-col-6">
                            {{$t('Monto')}}: <strong>{{moneda(police.evaluacion.montofinal)}}</strong>
                        </div>	
                    </div>                   
                </div>
            </div>
            <!-- PROYECTO -->
            <div class="confirmation-content" v-if="police.tipo==4">
                <div class="p-field">
                    <h4>{{$t('Información Básica')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-3">
                            {{$t('Fecha')}}: <strong>{{convfech(police.fecha)}}</strong>			
                        </div>
                        <div class="p-col-3">
                            {{$t('Número ID')}}: <strong>{{police.code}}</strong>			
                        </div>
                        <div class="p-col-3" v-if="police.tipocliente!=2">
                            {{$t('Sexo')}}: 
                            <strong v-if="i18n.locale() == 'es'"> {{police.sexnombre}}</strong> 			
                            <strong v-if="i18n.locale() == 'en'"> {{police.sexname}}</strong> 			
                        </div>
                        <div class="p-col-6">
                            {{$t('Nombre Completo')}}: <strong>{{police.nombre}}</strong>			
                        </div>
                        <div class="p-col-6">
                            {{$t('Correo')}}: <strong>{{police.correo}}</strong>			
                        </div>
                        <div class="p-col-4" v-if="police.tipocliente!=2">
                            {{$t('Fecha de nacimiento')}}: <strong>{{police.fechanac}}</strong>			
                        </div>
                        <div class="p-col-2" v-if="police.tipocliente!=2">
                            {{$t('Edad')}}: <strong>{{police.edad}}</strong>			
                        </div>
                        <div class="p-col-6" v-if="i18n.locale() == 'es'">
                            {{$t('Nacionalidad')}}: <strong>{{police.nacionalidad}}</strong> 			
                        </div>
                        <div class="p-col-6" v-else>
                            {{$t('Nacionalidad')}}: <strong>{{police.nationality}}</strong> 			
                        </div>
                        <div class="p-col-4">
                            {{$t('Localidad')}}: <strong>{{police.localidadn}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Teléfono móvil')}}: <strong>{{police.celular}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Teléfono privado')}}: <strong>{{police.tlflocal}}</strong>			
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Datos del Proyecto')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-12">
                            {{$t('Descripción')}}:<strong>{{police.descripcion}}</strong>
                        </div>
                        <div class="p-col-4">
                            {{$t('Fecha de Inicio')}}:<strong>{{police.fech_ini}}</strong>
                        </div>
                        <div class="p-col-4">
                            {{$t('Fecha de Finalización')}}:<strong>{{police.fech_fin}}</strong>
                        </div>
                        <div class="p-col-2">
                            {{$t('Contrato')}}
                        </div>
                        <div class="p-col-2">
                            <img style="width: 50px;" src="images/pdf.jpg" @click="VerPDF(police.pdf_contrato)"/>
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <h4>{{$t('Costo Total del Proyecto')}}</h4>
                    <DataTable :value="police.descrip" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                            :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                        <Column :header="$t('Descripción')">
                            <template #body="slotProps">
                                {{slotProps.data.opcion}}
                            </template>
                        </Column>
                        <Column :header="$t('Valor')">
                            <template #body="slotProps">
                                {{slotProps.data.valor}}
                            </template>
                        </Column>
                        <Column :header="$t('Rate')+' %'">
                            <template #body="slotProps">
                                {{slotProps.data.evaluo}}
                            </template>
                        </Column>
                    </DataTable>
                </div>			
                <div class="p-field">
                    <h4>{{$t('Evaluación')}}</h4>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-6">
                            {{$t('Tipo de Póliza')}}: 
                            <strong v-if="i18n.locale() == 'es'">{{police.evaluacion.tipopoliza_nombre}}</strong>
                            <strong v-if="i18n.locale() == 'en'">{{police.evaluacion.tipopoliza_name}}</strong>
                        </div>
                        <div class="p-col-6">
                            {{$t('Duración')}}: 
                            <strong v-if="i18n.locale() == 'es'">{{police.duracion_nombre}}</strong>
                            <strong v-if="i18n.locale() == 'en'">{{police.duracion_name}}</strong>
                        </div>
                        <br><br>	
                        <div class="p-col-12">
                            {{$t('Observación')}}: <strong>{{police.evaluacion.comentario}}</strong>
                        </div>
                        <br><br>
                        <div class="p-col-6">
                            {{$t('Pagos')}}: <strong>{{police.cuota_valor}}</strong>
                        </div>	
                        <div class="p-col-6">
                            {{$t('Monto')}}: <strong>{{moneda(police.evaluacion.montofinal)}}</strong>
                        </div>	
                    </div>                   
                </div>
            </div>
            <template #footer>
                <Button :label="$t('Cerrar')" icon="pi pi-times" class="p-button-danger" @click="close"/>
            </template>
        </Dialog>
	</div>
</template>

<script>

import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		},
        police: {
            type: Object,
            default: () => {}
		},
        duracion: {
            type: Array,
            default: () => []
		},
        cuotas: {
            type: Array,
            default: () => []
		}

	},
    data() {
        return {
            displayview: false,
            size: '60vw',
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
		this.url = url;
		this.i18n = useI18nPlugin();
    },
    methods: {
        close() {
            this.displayview = false;
            this.$emit('close-view', event);
        },
        onMenuToggle(event) {
            this.$emit('aprob-view', event);
            this.$emit('close-view', event);
        },	
        VerPDF(document){
            window.open(url.upload+document+'.pdf', '_blank');
		},
		VerIMG(data){
			window.open(data, '_blank');
		},
		VerMENS(data){
			this.mensaje = data;
			this.DiagMENS = true;
		},
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: "ANG"}).format(parseFloat(number).toFixed(2));
		},

	},
    watch: {
		display(newValue) {
			this.displayview = newValue;
		}
	},


}
</script>